import React, { useState } from 'react'
import Button from '../../../../components/Yard/Button/Button';
import InputField from '../../../../components/Yard/InputField/InputField';
import Date from '../../../../components/Yard/date/Date';
import Time from '../../../../components/Yard/time/Time';

const Entrant = () => {
    const [first, setFirst] = useState("First name");
    const [last, setLast] = useState("Last name");
    const [sign, setSign] = useState();
    const [sgn, setSgn] = useState(false)
    const changeHandler = () => {
        setSgn(true)
    }
    return (
        <>
            <div className="entrant">
                <div className="entrant_head">
                    <h3>entrant details</h3>
                    <Button className="entrant_add" name="add more" />
                </div>
                <div className="entrant_container">
                    <div className="entrant_name">
                        <div className="entrant_first_name">
                            <p>name</p>
                            <InputField idName="entrant_inpt_first_id" className="entrant_inpt_first" value={first} change={setFirst} />
                        </div>
                        <InputField idName="entrant_inpt_second_id" className="entrant_inpt_second" value={last} change={setLast} />
                    </div>
                    <div className="entrant_date_time">
                        <div className="entrant_date">
                            <p>date</p>
                            <Date />
                        </div>
                        <div className="entrant_time">
                            <p>time</p>
                            <Time />
                        </div>
                    </div>
                    <div className="sign_container">
                        <div className="sign_head">
                            <h3>signature</h3>
                            <Button className="sign_add" name="add" />
                        </div>
                        <InputField idName="sign_input_id" className="sign_input" value={sign} change={changeHandler} />
                        <Button className={sgn ? ("sign_complete_active") : ("sign_complete")} name="sign" />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Entrant;