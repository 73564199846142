import React from 'react'

const Para_3 = () => {
    const para_3_style = {
        width: "100%",
        minHeight: "20vh",
        background: "#FFFFFF",
        color: "#333333",
        fontSize: "1.22rem",
        fontFamily: "Helvetica",
        fontWeight: "medium",
        margin: "20px 0px",
        padding: "20px 20px",
        borderRadius: "10px",
        wordSpacing: ".1rem"
    }
    return (
        <>
            <p style={para_3_style}>I (The undersigned) herby acknowledge that Understand the processes,
            control measures and precautions to be observed for this confined space entry.
            I will comply with these requirements at all times and immediately report any new
             or unforeseen hazards that present a risk of the work.</p>
        </>
    )
}
export default Para_3;