import React, { useState } from 'react'
import "./Date.css"
import calender from "../../../images/inspection/date-range-img.svg"
import InputField from "../InputField/InputField";

const Date = (props) => {
    const [date, setDate] = useState("01/20/2021")
    return (
        <>
            <div className="date">
                <InputField className="date_fld" type="text" name="date" value={date} change={setDate} label={props.label_1} />
                <div className="date_image">
                    <img src={calender} alt="calender img" />
                </div>
            </div>
        </>
    )
}
export default Date;