import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import ".././Safety.css";
import { connect } from "react-redux"
import { truck_content } from "../../../../actions/yardActions/TruckAction";
//import { useHistory } from "react-router-dom";
import circle_color from "../../../../images/safety/circle_color.svg";
import circle from "../../../../images/safety/circle.svg"
import circle_blank_color from "../../../../images/safety/circle_blank_color.svg"
import Header from '../../../../components/Yard/common/Header';
import Update from '../../../../components/Yard/update/Update';
import Para_3 from '../comp/Para_3';
import Entrant from '../comp/Entrant';
import DateTime from "../../../../components/Yard/dateTime/DateTime";
import Button from "../../../../components/Yard/Button/Button";
import {Routes} from '../../../../utils/route';
import {Spin } from 'antd';
const Safety_Part_D = (props) => {
    const id=props.id
    const [loading, setLoading] = useState (true);
    const [state, setState] = useState("");
    useEffect(() => {
        const callback=(val)=>{
            if(val===true){
                setLoading(false);
                props.truck_content(id);
            }}
          Routes('yard',callback)
    }, []);
    useEffect(()=>{
        if (props.single_truck !== null) {
            setState(props.single_truck);
        }
    },[props.single_truck])
    if(!loading){
    return (
        <>
            <section className="safety">
                <Header headName="safety details" LinkName={`/yard/safety/safety_part_c/${id}`} />
                <div className="safety_container">
                    <Update
                        update="safety_update"
                        circleImg_1={circle_blank_color} circleImg_2={circle_blank_color}
                        circleImg_3={circle_blank_color} circleImg_4={circle_color} circleImg_5={circle}
                        border_1="safety_part_d_border_1" border_2="safety_part_d_border_2"
                        border_3="safety_part_d_border_3" border_4="safety_border_4"
                    />
                    <div className="safety_part_a">
                        <p>confined space entry permit</p>
                        <h3>Part d - Entry activities</h3>
                    </div>
                    <Para_3 />
                    <Entrant />
                    <DateTime label_1="date" label_2="time" />
                    <div className="part_d_button">
                        <Link to={`/yard/safety/safety_part_c/${id}`}><Button className="part_d_button_1" name="Previous" /></Link>
                        <Link to={`/yard/safety/safety_part_e/${id}`}><Button className="part_d_button_2" name="next" /></Link>
                    </div>
                </div>
            </section>
        </>
    )
}else{
    return(<div className="spinner-main"><Spin/></div>)
  }
}
const mapStateToProps = state => ({
    truckData:state.truck.truckData,
    single_truck:state.truck.single_truck
  })
export default connect(mapStateToProps, {truck_content})(Safety_Part_D)