import React, { useState } from 'react'
import "./Time.css"
import watch from "../../../images/inspection/watch.svg"
import InputField from "../InputField/InputField";

const Time = (props) => {
    const [time, setTime] = useState("10:30")
    return (
        <>
            <div className="time">
                <InputField className="time_fld" type="text" name="time" value={time} change={setTime} />
                <div className="time_image">
                    <img src={watch} alt="watch img" />
                </div>
            </div>
        </>
    )
}
export default Time;